@font-face {
  font-family: "SoehneSchmalLeicht";
  src: local("soehne-schmal-leicht"),
    url(../public/fonts/SoehneSchmalLeicht/soehne-schmal-leicht.ttf)
      format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url(../public/fonts/Roboto/Roboto-Regular.ttf) format("ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(../public/fonts/Roboto/Roboto-Italic.ttf) format("ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(../public/fonts/Roboto/Roboto-Bold.ttf) format("ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(../public/fonts/Roboto/Roboto-BoldItalic.ttf) format("ttf");
  font-style: italic;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("~leaflet/dist/leaflet.css");
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}
